/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../../constants'
import { addFlashMessage } from '../../../app/behaviors/flash-messages'
import { retrieveURL } from '@vidday/utils'

/* ******************************
 * REPORT Zipped PROGRESS
 ***************************** */

export function zipProgress(percentage) {
	return {
		type: types.COMPRESS_VIDDAY_PACKAGE_PROGRESS,
		payload: { percentage: percentage },
	}
}

/* ******************************
 * COMPRESS MEDIA PACKAGE FOR DOWNLOAD
 ***************************** */
export function compressViddayPackage(uuid) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.COMPRESS_VIDDAY_PACKAGE_REQUEST,
					{
						type: types.COMPRESS_VIDDAY_PACKAGE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.COMPRESS_VIDDAY_PACKAGE_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/admin/viddaypackage/' + uuid,
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}
