import { useState, useEffect } from 'react'
import usePublishedMedia from './usePublishedMedia'
import { useEventContext } from './../contexts/EventProvider'
import axios from 'axios'

/**
 * Convert bytes to human readable string. Rounds up to 2 decimal positions
 */
const humanReadable = (bytes) => {
	if (bytes === 0) {
		return '0.00 B'
	}
	const e = Math.floor(Math.log(bytes) / Math.log(1024))
	return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

/**
 * Retrieve the size of a downloadable file
 *
 * @param {'videoDownload' | 'mediaPackageDownload'} type - The type of file to get the size for.
 *   - 'videoDownload': Uses the video.signedDownloadLocationUrl to get the size.
 *   - 'mediaPackageDownload': Uses the event.signedPackageUrl to get the size.
 *
 * @returns {string} The size of the file in a human-readable format.
 */
const useVideoSize = (type) => {
	const video = usePublishedMedia()
	const { event } = useEventContext()
	const [size, setSize] = useState('')

	useEffect(() => {
		const fetchSize = async (url) => {
			try {
				const response = await axios.head(url)
				const bytes = response.headers['content-length']
				const fileSize = humanReadable(bytes)
				setSize(fileSize)
			} catch (error) {
				console.error('Error fetching file size:', error)
			}
		}

		let url = null

		switch (type) {
			case 'videoDownload':
				url = video?.signedDownloadLocationUrl
				break
			case 'mediaPackageDownload':
				url = event?.signedPackageUrl
				break
			default:
				console.log('Invalid type provided:', type)
				return
		}

		if (url) {
			fetchSize(url)
		}
	}, [type, video, event])

	return size
}

export default useVideoSize
