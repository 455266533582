import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { useRoleContext } from '../../../../../contexts/RoleProvider'
import { ArrowForwardRounded, FileDownloadRounded } from '../../../../atoms/Icon'
import useVideoSize from '../../../../../hooks/useVideoSize'

export const DownloadVideoSelector = ({ isPresentation, onClick, variant, ...rest }) => {
	const { uuid } = useParams()
	const { role } = useRoleContext()
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(uuid)
	const location = useLocation()
	const handleDownload = () => navigate(`${handlers.public_download}${location.search}`)
	const size = useVideoSize('videoDownload')

	const cover =
		role == 'public'
			? '/assets/images/graphics/navigation-cards/vidday-video-download'
			: '/assets/images/graphics/navigation-cards/vidday-video-download'

	return (
		<NavCard variant={variant} {...rest} onClick={onClick ? onClick : handleDownload}>
			<NavCardCover path={cover} ext="png" />
			<NavCardContent
				title={isPresentation && role == 'recipient' ? 'Make it a Keepsake' : 'Free Download'}
				description={isPresentation && role == 'recipient' ? 'Free Download' : size ? `MP4 - ${size}` : 'MP4'}
			/>
			<NavCardActions>
				<NavCardButton title="Click to download" aria-label="Click to download">
					{onClick ? <FileDownloadRounded /> : <ArrowForwardRounded />}
				</NavCardButton>
			</NavCardActions>
		</NavCard>
	)
}
