import React, { useState, useEffect } from 'react'
import { VStack, Text, Button, Heading, Image, HStack, Spinner } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import useVideoSize from '../../../hooks/useVideoSize'
import { useEventContext } from '../../../contexts/EventProvider'
import { useDispatch } from 'react-redux'
import { compressViddayPackage } from '../../../../api/admin/workers/vidday-package'
import { loadEvent } from '../../../../api/app/events'
import { useSocketContext } from '../../../../v2/contexts/SocketProvider'

const ModalDownloadEventMedia = ({ onClose, isOpen }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-theatre-download-video-modal',
		ext: 'png',
	})
	const { event } = useEventContext()
	const dispatch = useDispatch()
	const [progressPercentage, setProgressPercentage] = useState(null)
	const [message, setMessage] = useState(null)
	const [mediaPackageLink, setMediaPackageLink] = useState(null)
	const [generatingPackage, setGeneratingPackage] = useState(null)
	const [mediaErrors, setMediaErrors] = useState([])
	const [fileSize, setFileSize] = useState(null)
	const [compressTimeout, setCompressTimeout] = useState(false)
	const archivedMedia = 'The operation is not valid'

	// Set mediaPackageLink if it's already available
	useEffect(() => {
		if (event?.package && event?.signedPackageUrl !== mediaPackageLink) {
			setMediaPackageLink(event.signedPackageUrl)
		}
	}, [event.package, event.signedPackageUrl, mediaPackageLink])

	// Call useVideoSize only if mediaPackageLink is available
	const urlFileSize = useVideoSize(mediaPackageLink ? 'mediaPackageDownload' : null)

	// Set fileSize when urlFileSize is available
	useEffect(() => {
		// Only update fileSize if it's different and exists
		if (urlFileSize && fileSize !== urlFileSize) {
			setFileSize(urlFileSize)
		}
	}, [urlFileSize, fileSize])

	// Handle package generation
	const handleMediaPackage = () => {
		if (!mediaPackageLink) {
			setGeneratingPackage(true)
			dispatch(compressViddayPackage(event?.uuid))
		}
	}

	// handler to start media package compression
	const onCreateMediaPackage = () => {
		handleMediaPackage(event?.uuid)
	}

	// Set a 10 min timer when we start generating package
	// If not rdy in 10min, modal redirects user to CCS
	useEffect(() => {
		let timer

		if (generatingPackage) {
			timer = setTimeout(() => {
				setCompressTimeout(true)
				setGeneratingPackage(false)
			}, 10 * 60 * 1000) // 10 minutes

			// Clear timer if media package link is set before timeout
			return () => {
				clearTimeout(timer)
			}
		}

		// Cleanup function if generatingPackage is false or when component unmounts
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [generatingPackage])

	const {
		users: { socket },
	} = useSocketContext()

	// set WS listeners and update state as necessary
	useEffect(() => {
		socket.on('mediaPackageProgress', (socketData) => {
			if (socketData.eventUuid == event.uuid) {
				// handles error related to archived media
				const errorMsg = socketData.message
				if (errorMsg.includes(archivedMedia)) {
					setMediaErrors([errorMsg])
					setGeneratingPackage(false)
				}
				// displays progress to useer
				setProgressPercentage(socketData.percentage)
				setMessage(socketData.message)

				// sets the link, reloads event
				if (socketData.link && socketData.link !== mediaPackageLink) {
					setMediaPackageLink(socketData.link)
					dispatch(loadEvent(event.uuid))
					setGeneratingPackage(false)
				}

				if (socketData.media) {
					setMediaErrors([...mediaErrors, socketData.media])
				}
			}
		})

		return () => {
			socket.off('mediaPackageProgress')
		}
	}, [event.uuid, socket])

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Download Media" />
						{mediaPackageLink ? (
							<>
								<Heading as="h3" size="lg" variant="hero" color="dark">
									Download Event Media
								</Heading>
								<Text color="dark">
									Ensure you're connected to WiFi as you're about to download a very large file.
								</Text>
								<Text color="dark">
									<strong>MP4 {fileSize && `- ${fileSize}`}</strong>
								</Text>
							</>
						) : generatingPackage ? (
							<>
								<Heading as="h3" size="lg" variant="hero" color="dark">
									Preparing Media Package
								</Heading>
								<Text color="dark">
									Hang tight! Your media package will be ready in 10 minutes or less.
								</Text>
								{progressPercentage !== null && (
									<Text color="dark" fontWeight="bold">
										Progress: {progressPercentage}%
									</Text>
								)}
								{message && (
									<HStack spacing="2" align="center">
										<Text color="dark">{message}</Text>
										{generatingPackage && <Spinner size="sm" />}
									</HStack>
								)}
							</>
						) : mediaErrors.length > 0 || compressTimeout ? (
							<>
								<Heading as="h3" size="lg" variant="hero" color="dark">
									Oops...There's an Issue.
								</Heading>
								<Text color="dark">
									Looks like we ran into some issues when creating your Media Package. Please reach
									out to our livechat support for assistance.
								</Text>
							</>
						) : (
							<>
								<Heading as="h3" size="lg" variant="hero" color="dark">
									Prepare Media Package
								</Heading>
								<Text color="dark">
									Click <strong>Prepare</strong> to start creating your media package.
								</Text>
								<Text color="dark">Your media package should be ready within 10 minutes.</Text>
							</>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="outline" onClick={onClose}>
							Close
						</Button>
						{mediaPackageLink ? (
							<Button
								as="a"
								title="Click to download"
								href={mediaPackageLink}
								download={mediaPackageLink}>
								Download
							</Button>
						) : mediaErrors.length > 0 || compressTimeout ? (
							<Button
								variant="solid"
								onClick={() => {
									if (window && window?.LC_API?.open_chat_window) {
										window.LC_API.open_chat_window()
										onClose()
									}
								}}>
								Chat with Support
							</Button>
						) : (
							<Button onClick={onCreateMediaPackage} isDisabled={generatingPackage}>
								{generatingPackage ? 'Preparing...' : 'Prepare'}
							</Button>
						)}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDownloadEventMedia
