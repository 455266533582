import * as React from 'react'
import { chakra, forwardRef, useMultiStyleConfig, StylesProvider, useStyles } from '@chakra-ui/system'
import { Button, Image, Box, Text, HStack, LightMode } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import Card from '../Card'
import { ArrowForwardRounded } from '../Icon'

export const NavWrapper = forwardRef((props, ref) => {
	const { children, ...rest } = props
	const styles = useMultiStyleConfig('NavCard', props)
	return (
		<StylesProvider value={styles}>
			<HStack spacing="1rem" w="full" cursor="pointer" ref={ref} {...rest}>
				{children}
			</HStack>
		</StylesProvider>
	)
})

export const NavCard = forwardRef((props, ref) => {
	const { children, ...rest } = props
	const styles = useMultiStyleConfig('NavCard', props)
	return (
		<StylesProvider value={styles}>
			<Card sx={styles.card} ref={ref} {...rest}>
				<HStack spacing="1rem" w="full" cursor={props.isThemeCover ? 'default' : 'pointer'}>
					{children}
				</HStack>
			</Card>
		</StylesProvider>
	)
})

/**
 * Cover Image (expect path to be a jpg image, passed as prop without extension)
 * @param {string} path the path to image/graphic WITH EXTENTION
 * @param {boolean} isCover apply 'cover' an 'center' css styles
 * @returns
 */
export const NavCardCover = ({ path, ext = 'jpg', useSrcSet = true, ...rest }) => {
	const styles = useStyles()

	const img = useImageGenerator({
		path: path,
		ext: ext,
		useSrcSet: useSrcSet,
	})

	return (
		<Box as="figure" __css={styles.cover} mr={ext == 'png' ? '-1rem' : null} {...rest}>
			<Image
				h="5rem"
				w="5rem"
				src={img.src}
				srcSet={img.srcset}
				// sx={imgStyles}
			/>
		</Box>
	)
}

/**
 *
 * @param {*} title
 * @param {*} subtitle
 * @returns
 */
export const NavCardContent = ({ title, description, children }) => {
	const styles = useStyles()

	return (
		<chakra.div __css={styles.content}>
			{!children && (
				<>
					<Text sx={styles.title} noOfLines={1}>
						{title}
					</Text>

					<Text sx={styles.description} noOfLines={1}>
						{description}
					</Text>
				</>
			)}
			{children && children}
		</chakra.div>
	)
}

export const NavCardActions = ({ useOptions, children, ...rest }) => {
	const styles = useStyles()

	return (
		<chakra.div __css={styles.actionsContainer} minW={useOptions ? ['120px', 'auto'] : 'auto'} {...rest}>
			<LightMode>{children}</LightMode>
		</chakra.div>
	)
}

export const NavCardButton = ({ children, ...rest }) => {
	const styles = useStyles()

	return (
		<Button sx={styles.cardButton} size="md" {...rest}>
			{children ? children : <ArrowForwardRounded color="link" />}
		</Button>
	)
}
